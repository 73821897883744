import React from 'react';
import styles from './Layout.module.css';  // Assuming the same CSS file is used for styling

import paec from "../../assets/paec.png"
import tile from "../../assets/tile.jpg"
import cyber from "../../assets/cyberneid.base.black.png"

interface CreditsProps {
    show: boolean;
    onClose: () => void;
}

const Credits: React.FC<CreditsProps> = ({ show, onClose }) => {
    if (!show) return null;

    return (
        <div className={styles.creditsOverlay}>
            <div className={styles.credits}>
                <div id="info-project" title="Sofia">
                    <center>
                        <h1>Credits</h1>
                        <br /><br />
                        <p>Università degli Studi di Napoli Federico II</p>
                        <p>Dipartimento di Scienze Economiche e Statistiche</p>

                        <img id="paec" src={paec} alt="Paec Logo" className={styles.paecLogo} />
                        <br /><br />
                        <p>
                            Finanziato dal MUR nell'ambito dei Piani di Orientamento e Tutorato (POT)
                        </p>
                        <div>
                            <h2>Gruppo di lavoro</h2>
                            <p>Cristina Davino, Roberto La Rovere, Stefano Marrone, Carlo Sansone.</p>
                        </div>
                        <p style={{ fontSize: '8pt' }}>
                            <a href="https://tilestorytellers.com" target="_blank" rel="noopener noreferrer">
                                <img id="tile-icon" src={tile} alt="Tile Icon" className={styles.tileLogo} />
                            </a>
                            &nbsp; &nbsp;&nbsp;&nbsp;
                            <a href="https://www.cyberneid.com" target="_blank" rel="noopener noreferrer">
                                <img id="cyberneid-icon" src={cyber} alt="Cyberneid Icon" className={styles.cyberLogo} />
                            </a>
                        </p>
                    </center>
                </div>
                <button onClick={onClose}>Chiudi</button>
            </div>
        </div>
    );
};

export default Credits;